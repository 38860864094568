const ACTIVE = 'active';

const popup = () => {
  const popupContainer = document.querySelector('.js-popup-container');
  const popups = document.querySelectorAll('.js-popup');
  if (popupContainer && popups) {
    Array.from(popups).forEach((singlePopup) => {
      popupContainer.appendChild(singlePopup);
    });
  }

  const autoPlayAudioVideo = (target, play = 0) => {
    const audio = target.querySelector('audio');
    if (audio) {
      if (play) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  };

  const toggles = document.querySelectorAll('.js-popup-open');

  if (toggles) {
    Array.from(toggles).forEach((toggle) => {
      toggle.addEventListener('click', (ev) => {
        ev.preventDefault();
        const target = document.getElementById(
          ev.currentTarget.getAttribute('data-popup'),
        );
        if (target) {
          target.classList.add(ACTIVE);
          autoPlayAudioVideo(target, 1);
        }
      });
    });
  }

  const close = document.querySelectorAll('.js-popup-close');

  if (close) {
    Array.from(close).forEach((singleClose) => {
      singleClose.addEventListener('click', (ev) => {
        ev.preventDefault();
        const target = document.querySelector(`.js-popup.${ACTIVE}`);
        if (target) {
          target.classList.remove(ACTIVE);
          autoPlayAudioVideo(target);
        }
      });
    });
  }
};

export default popup;
