const NAV_OPEN = 'menu-open';

const menu = () => {
  const toggle = document.querySelector('.js-toggle-nav');

  if (toggle) {
    const body = document.querySelector('body');
    toggle.addEventListener('click', (ev) => {
      ev.preventDefault();
      body.classList.toggle(NAV_OPEN);
    });
  }
};

export default menu;
