const scrollTop = () => {
  const toTop = document.querySelector('.js-scroll-top');

  if (toTop) {
    toTop.addEventListener('click', (ev) => {
      ev.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
};

export default scrollTop;
