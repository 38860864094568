/*
  Project: F4ICA
  Author: Marcin Ziółek
 */

import goDown from './modules/go-down';
import home from './modules/home';
import map from './modules/map';
import menu from './modules/menu';
import newsletter from './modules/newsletter';
import slider from './modules/slider';
import video from './modules/video';
import filter from './modules/filter';
import popup from './modules/popup';
import scrollTop from './modules/scroll-top';
import accordion from './modules/accordion';
import jumpToSection from './modules/jump-to-section';
import search from './modules/search';

menu();
newsletter();
home();
goDown();
video();
map();
slider();
filter();
popup();
scrollTop();
accordion();
jumpToSection();
search();
