const video = () => {
  const toggles = document.querySelectorAll('.js-play-video');

  if (toggles) {
    Array.from(toggles).forEach((toggle) => {
      toggle.addEventListener('click', (ev) => {
        ev.preventDefault();
        const target = document.getElementById(
          ev.currentTarget.getAttribute('data-video'),
        );
        if (target) {
          target.innerHTML = target.textContent;
          target.classList.add('show');
        }
      });
    });
  }
};

export default video;
