const goDown = () => {
  const links = document.querySelectorAll('.js-go-down');

  if (links) {
    Array.from(links).forEach((link) => {
      link.addEventListener('click', (ev) => {
        ev.preventDefault();
        const block = link.closest('.c-block');
        if (block) {
          const nextBlock = block.nextElementSibling;
          const offset = 80;
          if (nextBlock) {
            window.scroll({
              top: nextBlock.offsetTop - offset,
              left: 0,
              behavior: 'smooth',
            });
          }
        }
      });
    });
  }
};

export default goDown;
