const hasValue = 'has-value';

const newsletter = () => {
  document.addEventListener('DOMNodeInserted', () => {
    const form = document.querySelectorAll(
      '.c-footer__newsletter-form .gfield',
    );

    if (form) {
      const formArr = Array.from(form);
      formArr.forEach((i) => {
        const input = i.querySelector('input');

        if (input.value) {
          i.classList.add(hasValue);
        } else {
          i.classList.remove(hasValue);
        }
      });
    }
  });

  document.addEventListener('input', (ev) => {
    const { target } = ev;
    const parent = target.parentNode;
    const grandParent = parent.parentNode;
    if (parent.classList.contains('ginput_container') && target.value) {
      grandParent.classList.add(hasValue);
    } else {
      grandParent.classList.remove(hasValue);
    }
  });
};

export default newsletter;
