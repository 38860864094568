const showLogo = 'show-logo';

const home = () => {
  const logo = document.querySelector('.c-header__logo');

  if (logo) {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 200) {
        document.body.classList.add(showLogo);
      } else {
        document.body.classList.remove(showLogo);
      }
    });
  }
};

export default home;
