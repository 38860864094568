/* global wp */
const ACTIVE = 'active';
const HIDE = 'hide';
const BOLD = 'u-text-bold';

const filter = () => {
  const toggle = document.querySelector('.js-filter-toggle');
  let toggleText = null;

  if (toggle) {
    toggleText = toggle.querySelector('.js-filter-type-text');

    toggle.addEventListener('click', (ev) => {
      ev.preventDefault();
      toggle.classList.toggle(ACTIVE);
    });

    document.addEventListener('click', (ev) => {
      const parent = ev.target.closest('.js-filter-toggle');
      if (ev.target !== toggle && parent !== toggle) {
        toggle.classList.remove(ACTIVE);
      }
    });
  }

  const form = document.querySelector('.js-form-filter');
  if (form) {
    form.addEventListener('change', (ev) => {
      const val = ev.target.value;
      if (val !== '*') {
        Array.from(
          document.querySelectorAll('.js-filter-wrapper [class*="region-"]'),
        ).forEach((item) => {
          item.classList.add(HIDE);
          if (item.parentNode.classList.contains('c-partner-preview__link'))
            item.parentNode.classList.add(HIDE);
        });
        const region = `.js-filter-wrapper .region-${val}`;
        Array.from(document.querySelectorAll(region)).forEach((item) => {
          item.classList.remove(HIDE);
          if (item.parentNode.classList.contains('c-partner-preview__link'))
            item.parentNode.classList.remove(HIDE);
        });
      } else {
        Array.from(
          document.querySelectorAll('.js-filter-wrapper [class*="region-"]'),
        ).forEach((item) => {
          item.classList.remove(HIDE);
          if (item.parentNode.classList.contains('c-partner-preview__link'))
            item.parentNode.classList.remove(HIDE);
        });
      }
      const showMore = document.querySelector('.js-show-more-partners');
      if (showMore) {
        showMore.remove();
      }
    });
  }

  const showStaff = document.querySelector('.js-show-more-staff');
  if (showStaff) {
    const staffHidden = document.querySelectorAll(
      '.c-staff-consultant-preview.hide',
    );
    showStaff.addEventListener('click', () => {
      Array.from(staffHidden).forEach((el) => {
        el.classList.remove(HIDE);
      });
      showStaff.remove();
    });
  }

  const showPartners = document.querySelector('.js-show-more-partners');
  if (showPartners) {
    const partnersHidden = document.querySelectorAll(
      '.js-filter-wrapper .c-partner-preview.hide',
    );
    showPartners.addEventListener('click', () => {
      Array.from(partnersHidden).forEach((el) => {
        el.classList.remove(HIDE);
      });
      showPartners.remove();
    });
  }

  const blogPosts = document.querySelector('.js-blog-posts');
  const formBlog = document.querySelector('.js-form-filter-blog');
  const loadMore = document.querySelector('.js-load-more-posts');
  const tagFilters = document.querySelectorAll('.js-filter-tag');

  const resetTagFilters = () => {
    Array.from(tagFilters).forEach((tagFilterA) => {
      tagFilterA.classList.remove(ACTIVE);
    });
  };

  let blogPostsOffset = 0;
  let selectedTerm = '*';
  if (formBlog) {
    formBlog.addEventListener('change', (ev) => {
      resetTagFilters();

      const val = ev.target.value;
      if (toggleText) {
        const title = ev.target.getAttribute('data-title');
        toggleText.textContent = title;
        if (val !== '*') {
          toggleText.classList.add(BOLD);
        } else {
          toggleText.classList.remove(BOLD);
        }
      }

      blogPostsOffset = 0;
      selectedTerm = val;

      wp.ajax
        .post('f4ice_ajax_get_posts', { term_id: val, offset: 0 })
        .done((response) => {
          const json = JSON.parse(response);
          let html = '';
          json.html.forEach((el) => {
            html += el;
          });
          blogPosts.innerHTML = html;

          if (loadMore) {
            if (json.count <= window.postsPerPage) {
              loadMore.classList.add(HIDE);
            } else {
              loadMore.classList.remove(HIDE);
            }
          }
        });
    });

    if (loadMore) {
      loadMore.addEventListener('click', () => {
        blogPostsOffset += window.postsPerPage;

        wp.ajax
          .post('f4ice_ajax_get_posts', {
            term_id: selectedTerm,
            offset: blogPostsOffset,
          })
          .done((response) => {
            const json = JSON.parse(response);
            let html = '';
            json.html.forEach((el) => {
              html += el;
            });
            blogPosts.innerHTML = html;

            if (window.postsPerPage + blogPostsOffset >= json.count) {
              loadMore.classList.add(HIDE);
            } else {
              loadMore.classList.remove(HIDE);
            }
          });
      });
    }
  }

  const resetCategoryFilter = () => {
    if (toggleText) {
      const defaultFormValue = formBlog.querySelector('[value="*"]');
      const { title } = defaultFormValue.dataset;
      if (title) {
        defaultFormValue.checked = true;
        toggleText.textContent = title;
        toggleText.classList.remove(BOLD);
      }
    }
  };

  if (tagFilters) {
    Array.from(tagFilters).forEach((tagFilter) => {
      tagFilter.addEventListener('click', (ev) => {
        resetTagFilters();
        resetCategoryFilter();
        ev.preventDefault();

        tagFilter.classList.add(ACTIVE);

        const { tag } = ev.currentTarget.dataset;
        wp.ajax
          .post('f4ice_ajax_get_posts_by_tag', {
            tag,
          })
          .done((response) => {
            const json = JSON.parse(response);
            let html = '';
            json.html.forEach((el) => {
              html += el;
            });
            blogPosts.innerHTML = html;
          });
      });
    });
  }
};

export default filter;
