/* global wp */
const ACTIVE = 'active';
const HIDE = 'hide';

const search = () => {
  const toggle = document.querySelector('.js-search-toggle');
  if (toggle) {
    toggle.addEventListener('click', (ev) => {
      ev.preventDefault();
      toggle.classList.add(ACTIVE);
    });
  }

  const loadMore = document.querySelector('.js-load-more-search-posts');
  let offset = 0;
  if (loadMore) {
    const postsWrapper = document.querySelector('.js-search-posts');
    loadMore.addEventListener('click', (ev) => {
      ev.preventDefault();

      const { s } = loadMore.dataset;
      offset += 6;
      wp.ajax
        .post('f4ice_ajax_get_more_search_posts', { offset, s })
        .done((response) => {
          const json = JSON.parse(response);
          let html = '';
          json.html.forEach((el) => {
            html += el;
          });
          postsWrapper.innerHTML = html;

          if (json.count <= 6 + offset) {
            loadMore.classList.add(HIDE);
          } else {
            loadMore.classList.remove(HIDE);
          }
        });
    });
  }
};

export default search;
