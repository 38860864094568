const jumpToSection = () => {
  const form = document.querySelector('.js-form-jump-to-section');
  if (form) {
    const sections = document.querySelectorAll('.js-section-id');
    if (sections) {
      let html = '';
      Array.from(sections).forEach((section) => {
        html += `<label>
          <input type="radio" name="section" value="${section.id}">
          <span class="c-full-partners__filter-val">${section.innerHTML}</span>
        </label>`;
      });
      form.innerHTML = html;
    }

    form.addEventListener('change', (ev) => {
      const val = ev.target.value;
      const section = document.getElementById(val);
      if (section) {
        const top = section.offsetTop - 100;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    });
  }
};

export default jumpToSection;
