import Swiper, {
  Navigation,
  Pagination,
  EffectFade,
  Thumbs,
  Mousewheel,
} from 'swiper';

Swiper.use([Navigation, Pagination, EffectFade, Thumbs, Mousewheel]);

const ACTIVE = 'active';
const slider = () => {
  const stories = document.querySelector('.js-slider');

  if (stories) {
    const toggleSlider = (s) => {
      if (window.outerWidth > 1225) {
        s.disable();
      } else {
        s.enable();
      }
    };

    // eslint-disable-next-line no-new
    new Swiper('.js-slider', {
      mousewheel: {
        forceToAxis: true,
      },
      slidesPerView: 'auto',
      on: {
        init: (s) => toggleSlider(s),
        resize: (s) => toggleSlider(s),
      },
    });
  }

  const testimonials = document.querySelector('.js-slider-testimonials');
  if (testimonials) {
    // eslint-disable-next-line no-new
    new Swiper('.js-slider-testimonials', {
      mousewheel: {
        forceToAxis: true,
      },
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  const timeline = document.querySelector('.js-slider-timeline');
  if (timeline) {
    const swiper = new Swiper('.js-slider-timeline', {
      mousewheel: {
        forceToAxis: true,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: true,
      breakpoints: {
        1024: {
          allowTouchMove: false,
        },
      },
    });

    const timelineNavWrapper = document.querySelector(
      '.js-timeline-nav-wrapper',
    );
    const timelineNav = document.querySelectorAll('.js-timeline-nav');
    if (timelineNav) {
      Array.from(timelineNav).forEach((nav) => {
        nav.addEventListener('click', (ev) => {
          Array.from(timelineNav).forEach((item) => {
            item.classList.remove(ACTIVE);
          });
          nav.classList.add(ACTIVE);
          const activeSlide = ev.currentTarget.getAttribute('data-slide');
          swiper.slideTo(activeSlide);
          timelineNavWrapper.classList.remove('active-slide-1');
          timelineNavWrapper.classList.remove('active-slide-2');
          timelineNavWrapper.classList.remove('active-slide-3');
          timelineNavWrapper.classList.add(`active-slide-${activeSlide}`);
        });
      });
    }
  }

  const featured = document.querySelector('.js-slider-featured');
  if (featured) {
    const toggleFeaturedSlider = (s) => {
      if (window.outerWidth < 480) {
        s.disable();
      } else {
        s.enable();
      }
    };

    // eslint-disable-next-line no-new
    new Swiper('.js-slider-featured', {
      mousewheel: {
        forceToAxis: true,
      },
      slidesPerView: 'auto',
      allowTouchMove: true,
      spaceBetween: 33,
      on: {
        init: (s) => toggleFeaturedSlider(s),
        resize: (s) => toggleFeaturedSlider(s),
      },
    });
  }

  const tags = document.querySelector('.js-slider-tags');
  if (tags) {
    // eslint-disable-next-line no-new
    new Swiper('.js-slider-tags', {
      mousewheel: {
        forceToAxis: true,
      },
      slidesPerView: 'auto',
      allowTouchMove: true,
      spaceBetween: 17,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  const galleries = document.querySelectorAll('.js-slider-gallery');
  if (galleries) {
    Array.from(galleries).forEach((gallery) => {
      const thumbsSwiper = gallery.getAttribute('data-thumb');
      const thumbs = new Swiper(thumbsSwiper, {
        mousewheel: {
          forceToAxis: true,
        },
        spaceBetween: 15,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
      });
      // eslint-disable-next-line no-new
      new Swiper(`#${gallery.id}`, {
        mousewheel: {
          forceToAxis: true,
        },
        slidesPerView: 1,
        thumbs: {
          swiper: thumbs,
        },
      });
    });
  }
};

export default slider;
