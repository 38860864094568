const ACTIVE = 'active';

const accordion = () => {
  const toggles = document.querySelectorAll('.js-accordion-toggle');

  if (toggles) {
    Array.from(toggles).forEach((toggle) => {
      toggle.addEventListener('click', () => {
        toggle.classList.toggle(ACTIVE);
        const container = toggle.nextElementSibling;

        if (!container.classList.contains(ACTIVE)) {
          container.classList.add(ACTIVE);
          container.style.height = 'auto';

          const height = `${container.clientHeight}px`;

          container.style.height = '0';

          setTimeout(() => {
            container.style.height = height;
          }, 0);
        } else {
          container.style.height = '0';

          container.addEventListener(
            'transitionend',
            () => {
              container.classList.remove(ACTIVE);
            },
            {
              once: true,
            },
          );
        }
      });
    });
  }
};

export default accordion;
