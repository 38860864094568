const showPopup = 'show';
const breakpoint = 768;

const map = () => {
  const popups = document.querySelectorAll('.js-map-popup');

  if (popups) {
    const popupsToggleList = Array.from(
      document.querySelectorAll('.js-map-popup-toggle'),
    );
    const changePopupsPosition = (windowW) => {
      const popupsMobile = document.querySelector('.js-popups-mobile');
      const popupsList = Array.from(popups);
      if (windowW >= breakpoint) {
        popupsList.forEach((item, index) => {
          popupsToggleList[index].appendChild(item);
        });
      } else {
        popupsList.forEach((item) => {
          popupsMobile.appendChild(item);
        });
      }
    };

    let windowW = window.outerWidth;
    changePopupsPosition(windowW);
    window.addEventListener('resize', () => {
      windowW = window.outerWidth;
      changePopupsPosition(windowW);
    });

    popupsToggleList.forEach((toggle) => {
      toggle.addEventListener('click', (ev) => {
        ev.stopPropagation();
        if (windowW < breakpoint) {
          const popup = document.querySelector(
            `.js-map-popup:nth-child(${toggle.getAttribute(
              'data-map-toggle',
            )})`,
          );
          popup.classList.toggle(showPopup);
        }
      });
    });

    document.addEventListener('click', () => {
      if (windowW < breakpoint) {
        Array.from(popups).forEach((popup) => {
          popup.classList.remove(showPopup);
        });
      }
    });
  }
};

export default map;
